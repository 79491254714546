.title-bar {
	margin-bottom: 0;
	min-height: $title-bar-height;

	.navbar-brand {
		margin: 0;
		font-weight: bold;
		padding: $title-bar-padding $title-bar-padding $title-bar-padding 0;
		height: $title-bar-height;
	}

	.navbar-nav {
		> li > a {
			color: $title-bar-color;
			font-weight: bold;

			@media (min-width: $screen-sm) {
				padding-top: $title-bar-padding;
				padding-bottom: $title-bar-padding;

				&.icon-menu-item {
					padding-top: $title-bar-padding - 2px;
					padding-bottom: $title-bar-padding - 2px;
				}
			}
		}
	}

	.dropdown-menu {
		> form {
			padding: 5px 10px;

			.form-group {
				margin-bottom: 10px;
			}
		}
	}
}