$font-size-base:          14px !default;

// Font sizes
@function em($px, $base: $font-size-base) {
	@return ($px / $base) * 1em;
}

// Colors
$white: #FFF;
$black: #000;
$gray1: #f2f2f2;
$gray3: #CCC;
$gray5: #595959;
$blue: #0093e7;
$blue2: #0d79d5;
$green: #97be3e;

// Helper Variables
$spacing-margin: 20px;

// Navbar
$navbar-default-link-border-bottom-height: 	4px;
$navbar-default-link-hover-border-color: 		$blue;
$navbar-default-link-active-border-color: 	$blue;

// Sidebar
$sidebar-dark-color: 						$black;
$sidebar-dark-bg: 								$gray1;
$sidebar-separator-color: 	$gray3;

$content-sidebar-width: 400px;
$content-sidebar-shadow-width: 6px;

// Nav Sidebar
$nav-sidebar-color:								$black;
$nav-sidebar-hover-border-width: 	4px;
$nav-sidebar-hover-border: 				$blue;
$nav-sidebar-icon-width: 					36px;

// Footer
$footer-height: 		60px;
$footer-color: 			$white;
$footer-bg: 				$gray5;

// Title Bar
$title-bar-color: 		$black;
$title-bar-padding: 	14px;
$title-bar-height: 		48px;

// Filter Bar
$filter-bar-bg: 						$gray1;
$filter-bar-border-color: 	$gray1;
$filter-bar-height: 				50px;
$filter-bar-form-margin: 		7px;
$filter-bar-form-padding: 	10px;

// RSI Buttons
$rsi-toggle-btn-color: 					$gray5;
$rsi-toggle-btn-hover-color: 		darken($gray5, 15%);
$rsi-toggle-btn-active-color: 	$blue;