#login-modal {
	@media (min-width: $screen-sm) {
		.modal-dialog {
			width: 305px;
			margin: 8% auto;
		}
	}

	.actions {
		.forgot-password {
			display: block;
			margin-bottom: 40px;
		}

		> p {
			margin: 0;
		}
	}
}

.login-form {
	margin-top: 20px;
	margin-bottom: 20px;
}