.slider {
	&.slider-horizontal {
		width: 100%;
	}

	&.slider-vertical {
		height: 100%;
	}
}

.slider-y-axis {
	.slider {
		margin-top: 16px;
	}
}

.slider-tick-label {
	font-size: 12px;
}