$font-size-h1:            em(18px) !default;
$font-size-h2:            em(17px) !default;
$font-size-h3:            em(16px) !default;
$font-size-h4:            em(15px) !default;
$font-size-h5:            em(14px) !default;
$font-size-h6:            em(13px) !default;

// Bootstrap Overrides
$link-color: $blue2;

// Buttons
$btn-primary-color:              $white !default;
$btn-primary-bg:                 $green !default;
$btn-primary-border:             $green !default;
//$btn-default-border:             $white !default;

//$btn-border-radius-base:         0px !default;
//$btn-border-radius-large:        0px !default;
//$btn-border-radius-small:        0px !default;

// Forms
$input-border-radius:            1px !default;
$input-height-base:              36px !default;

// Navbar Overrides
$navbar-height:										 60px !default;
$navbar-default-bg:                $gray1 !default;

$navbar-default-link-color:                $black !default;
$navbar-default-link-active-color:         $black !default;