.sidebar {
	margin-top: 5px;

	@media (min-width: 768px) {
		position: relative;
	}

	&:before {
		content: '';
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		width: inherit;
		z-index: -1;
		background-color: inherit;
	}

	.divider {
		margin: 26px 0 20px;
		border-bottom: 1px solid $sidebar-separator-color;
	}

	.accordion-list {
		margin-top: 50px;
	}
}

.sidebar-dark {
	margin-top: 34px;
	color: $sidebar-dark-color;
	background-color: $sidebar-dark-bg;
}

.content-sidebar {
	width: $content-sidebar-width + $content-sidebar-shadow-width;
	background-color: $sidebar-dark-bg;

	position: fixed;
	right: -$content-sidebar-shadow-width;
	top: $navbar-height + $title-bar-height + $filter-bar-height + 4px;
	bottom: $footer-height;
	z-index: 1000;

	@include box-shadow(-$content-sidebar-shadow-width, 2px, 10px, rgba(0, 0, 0, 0.2));

	transition: transform 500ms;
	transform: translateX(100%);

	&.open {
		transform: translateX(0);
	}

	.sidebar-header {
		> iframe {
			width: $content-sidebar-width !important;
			height: $content-sidebar-width !important;
		}
	}

	.sidebar-content {
		padding: 15px;
	}

	#station-inspector-map {
		height: $content-sidebar-width;
	}
}