.btn-full {
	width: 100%;
}

.rsi-btn-group {
	.btn {
		@extend .btn-default;
		font-weight: bold;

		&.active {
			color: #FFF;
			background-color: $btn-primary-bg;
			border-color: $btn-primary-border;
			box-shadow: none;

			&:hover,
			&:focus {
				background-color: darken($btn-primary-bg, 10%);
				border-color: darken($btn-primary-border, 10%);
			}
		}
	}

	.btn-map {
		padding: 3.7px 10px;

		.icon-rsi-map {
			font-size: 20px;
		}
	}
}

.rsi-toggle-btn {
	color: $rsi-toggle-btn-color;

	&:hover{
		color: $rsi-toggle-btn-hover-color;
	}

	&:focus {
		color: $rsi-toggle-btn-color;
	}

	&.active {
		color: $rsi-toggle-btn-active-color;
	}
}