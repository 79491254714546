.icon-menu-item {
	display: table;

	> span {
		//display: table-cell;
		vertical-align: middle;
	}

	.icon {
		padding-right: 10px;
		font-size: 1.8em;
		width: 36px;
	}
}