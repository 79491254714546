@mixin nav-sidebar-hover-border() {
	&:before {
		height: 45px;
		display: block;
		width: $nav-sidebar-hover-border-width;
		background: $nav-sidebar-hover-border;
		content: '';
		position: absolute;
		margin-left: -($grid-gutter-width/2);
	}
}

.nav-sidebar {
	> li {
		margin: 2px 0;
		> a {
			padding: 10px 0;
			color: $nav-sidebar-color;
			width: 100%;

			.icon {
				width: $nav-sidebar-icon-width;
			}

			&.no-icon {
				padding: 13px 0;
				display: block;
				margin-left: $nav-sidebar-icon-width;
			}
		}

		&:hover {
			@include nav-sidebar-hover-border();
		}

		&.panel {
			background-color: transparent;
			border: none;
			box-shadow: none;
		}
	}

	> .active {
		@include nav-sidebar-hover-border();

		> a {
			font-weight: bold;
		}
	}
}

.nav-sidebar-inner {
	padding-left: 50px;

	> li > a {
		color: $nav-sidebar-color;
		padding: 2px 0;
		display: block;
	}

	.active {
		> a {
			font-weight: bold;
		}
	}
}