.footer {
	color: $footer-color;
	background-color: $footer-bg;

	.copyright {
		font-size: 0.93em;
		margin: 20px 0 20px;

		> p {
			margin-bottom: 0;
			line-height: 20px;
		}
	}
}