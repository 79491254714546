.data-chart {
	.data-chart-item {
		overflow: hidden;

		> div {
			width: 50%;
			padding: 0 10px;
			float: left;
		}
	}

	.data-chart-label {
		text-align: right;
		font-weight: bold;
	}
}