$icomoonFontPath: "../fonts/icomoon" !default;

$icon-rsi-map: "\e905";
$icon-rsi-binoculars: "\e902";
$icon-rsi-new: "\e903";
$icon-rsi-ranking: "\e904";
$icon-rsi-location: "\e900";
$icon-rsi-bars: "\e901";
$icon-rsi-profile: "\e14f";
$icon-rsi-calender: "\e916";
$icon-rsi-star: "\e838";
$icon-rsi-historical: "\e8b3";
$icon-rsi-info: "\e88e";
$icon-rsi-csv: "\e24d";
$icon-rsi-pdf: "\e415";
$icon-rsi-plus-circle: "\f055";
$icon-rsi-minus-circle: "\f056";

@font-face {
	font-family: 'icomoon';
	src:url('#{$icomoonFontPath}.eot?cmiijm');
	src:url('#{$icomoonFontPath}.eot?cmiijm#iefix') format('embedded-opentype'),
	url('#{$icomoonFontPath}.ttf?cmiijm') format('truetype'),
	url('#{$icomoonFontPath}.woff?cmiijm') format('woff'),
	url('#{$icomoonFontPath}.svg?cmiijm#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon-kallo]:before {
	font-family: 'icomoon';
	content: attr(data-icon-kallo);
	speak: none;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}

/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="icon-"] {
*/
.icon-rsi-binoculars, .icon-rsi-new, .icon-rsi-ranking, .icon-rsi-location, .icon-rsi-bars, .icon-rsi-profile,
.icon-rsi-calender, .icon-rsi-star, .icon-rsi-historical, .icon-rsi-info, .icon-rsi-csv, .icon-rsi-map, .icon-rsi-pdf,
.icon-rsi-plus-circle, .icon-rsi-minus-circle {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}

.icon-rsi-binoculars {
	&:before {
		content: $icon-rsi-binoculars;
	}
}
.icon-rsi-new {
	&:before {
		content: $icon-rsi-new;
	}
}
.icon-rsi-ranking {
	&:before {
		content: $icon-rsi-ranking;
	}
}
.icon-rsi-location {
	&:before {
		content: $icon-rsi-location;
	}
}
.icon-rsi-bars {
	&:before {
		content: $icon-rsi-bars;
	}
}
.icon-rsi-profile {
	&:before {
		content: $icon-rsi-profile;
	}
}
.icon-rsi-calender {
	&:before {
		content: $icon-rsi-calender;
	}
}
.icon-rsi-star {
	&:before {
		content: $icon-rsi-star;
	}
}
.icon-rsi-historical {
	&:before {
		content: $icon-rsi-historical;
	}
}
.icon-rsi-info {
	&:before {
		content: $icon-rsi-info;
	}
}
.icon-rsi-csv {
	&:before {
		content: $icon-rsi-csv;
	}
}
.icon-rsi-map {
	&:before {
		content: $icon-rsi-map;
	}
}
.icon-rsi-pdf {
	&:before {
		content: $icon-rsi-pdf;
	}
}
.icon-rsi-plus-circle {
	&:before {
		content: $icon-rsi-plus-circle;
	}
}
.icon-rsi-minus-circle {
	&:before {
		content: $icon-rsi-minus-circle;
	}
}