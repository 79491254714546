.embed-map {
	&.embed-responsive {
		padding-bottom: 40%;
	}
}

.map {
	height: 700px;
	width: 100%;

	.popover {
		width: 200px;
	}

	.popover-content {
		> p {
			margin: 0;
		}
	}
}