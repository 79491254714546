body, html {
	height: 100%;
}

/******** Defaults *******/
html {
	font-size: 100%;

	body {
		font-size: ($font-size-base / 16px) * 100%; // 16px is the base browser font size
	}
}

h1, h2, h3, h4, h5, h6 {
	font-weight: bold;
}

a:hover, a:focus {
	text-decoration: none;
}

.main-content {
	margin-top: 20px;
}

@media (min-width: $screen-sm) {
	#wrapper {
		min-height: 100%;
		margin-top: -$navbar-height;
		margin-bottom: -$footer-height;
	}

	#wrapper:after {
		content: "";
		display: block;
		height: $footer-height;
	}

	#main {
		margin-top: $navbar-height;
	}
}
