.filter-bar {
	border-radius: 0;
	background-color: $filter-bar-bg;
	border-color: $filter-bar-border-color;
	min-height: $filter-bar-height;
	margin-right: -15px;
	margin-bottom: 0;

	@media (min-width: $screen-sm) {
		.navbar-right {
			margin-right: 0;
		}
	}

	.navbar-form {
		margin-top: $filter-bar-form-margin;
		margin-bottom: $filter-bar-form-margin;
		padding-left: $filter-bar-form-padding;
		padding-right: $filter-bar-form-padding;

		overflow: hidden;
	}

	.filter-bar-btn-groups {
		margin-top: 2px;
		padding-left: 10px;
		padding-right: 10px;

		.btn-group {
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	.icon-menu-item {
		padding: 0 5px;
		display: inline-block;

		.icon {
			padding: 0;
		}
	}

	.rsi-filter-toggle {
		&.active {
			display: none;
		}
		.icon {
			font-size: 1.4em;
		}
	}

	#third-filter-group {
		display: none;

		&.open {
			display: inline-block;
		}
		.icon {
			font-size: 1.4em;
		}
	}

	.filter-slider {
		margin-left: 40px;
		width: 500px;

		.slider.slider-horizontal {
			margin-bottom: 16px !important;
		}

		.slider-selection {
			background: transparent;
			@include box-shadow(0, 0, 0, 0);
		}
		.slider-tick.round.in-selection{
			// From the slider plugin
			background-image:-webkit-linear-gradient(top,#f9f9f9 0,#f5f5f5 100%);
			background-image:-o-linear-gradient(top,#f9f9f9 0,#f5f5f5 100%);
			background-image:linear-gradient(to bottom,#f9f9f9 0,#f5f5f5 100%);

			@include box-shadow(0, -1px, 0, rgba(0,0,0,0.15), inset);
		}
	}
}