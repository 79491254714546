@mixin navbar-hover-border() {
	&:after {
		height: $navbar-default-link-border-bottom-height;
		display: block;
		width: 100%;
		background: $navbar-default-link-active-border-color;
		content: '';
		position: absolute;
	}
}

.navbar-default {
	margin-bottom: 0;
	border-bottom: none;

	.navbar-nav {
		> li:hover {
			@include navbar-hover-border();
		}

		> .active {
			> a {
				font-weight: bold;
				background-color: transparent;
			}

			@include navbar-hover-border();

			> a:hover {
				background-color: transparent;
			}
		}
	}
}